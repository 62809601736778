import {Investment, Transaction, TransactionType} from "../Domain";
import React, {FormEvent} from "react";
import "./SellDialog.css";
import {v4 as uuid} from "uuid";
import moment from "moment";
import {gql, useMutation} from "@apollo/client";

const reducer = (state: Transaction, action: { name: string, value: string }) => {
    let value: any = action.value;
    if (action.name !== "timestamp") value = +value;
    return {
        ...state,
        [action.name]: value
    };
};

const ADD_TRANSACTION = gql`
    mutation addTransctaion($transaction: TransactionInput!) {
        addTransaction(transaction: $transaction)
        {
            id
            costs
            name
            sum
        }
    }
`;

export const SellDialog = (props: { stock: Investment, setDialogOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void, open: boolean }) => {

        const [addTransaction] = useMutation(ADD_TRANSACTION);

        const initialState: Transaction = {
            costs: 1,
            sum: 0,
            transactionType: TransactionType.SELL,
            relatedTransactionId: props.stock.transactions[props.stock.transactions.length - 1].id,
            name: props.stock.name,
            isin: props.stock.isin,
            price: 0,
            size: props.stock.numberOfShares,
            id: uuid(),
            timestamp: new Date().toLocaleDateString("de-DE")
        };
        const [transaction, dispatch] = React.useReducer(reducer, initialState);

        const createTransaction = (event: FormEvent<HTMLFormElement>) => {
            transaction.sum = -(transaction.price * transaction.size + transaction.costs);
            transaction.timestamp = moment(transaction.timestamp, "DD.MM.YYYY").toDate().toISOString();
            console.log(transaction);
            addTransaction({variables: {transaction}})
                .then(() => console.log("Transaction added successfully"))
                .catch(reason => {
                    console.log(`Error when adding transaction: ${reason}`)
                    console.log(reason.networkError.result.errors);
                });
            event.preventDefault();
        };

        return (
            <React.Fragment>
                {props.open ?
                    <div id="myModal" className="sellDialog">
                        <div className="sellDialog-content">
                            <form onSubmit={createTransaction}>
                                Number of shares: <input type="number" value={transaction.size} required={true}
                                                         onChange={event => dispatch({
                                                             name: "size",
                                                             value: event.target.value
                                                         })
                                                         }/><br/>
                                Price: <input type="number" step="0.01" required={true} value={transaction.price}
                                              onChange={event => dispatch({
                                                  name: "price",
                                                  value: event.target.value
                                              })}/><br/>
                                Costs: <input type="number" step="0.01" required={true} value={transaction.costs}
                                              onChange={event => dispatch({
                                                  name: "costs",
                                                  value: event.target.value
                                              })}/><br/>
                                Date:<input type="string" pattern="\d{1,2}\.\d{1,2}\.\d{4}"
                                            value={transaction.timestamp}
                                            onChange={event => dispatch({
                                                name: "timestamp",
                                                value: event.target.value
                                            })} required={true}/><br/>
                                <input type="submit"/>
                                <button onClick={() => props.setDialogOpen(false)}>close</button>
                            </form>
                        </div>
                    </div>
                    : null}
            </React.Fragment>
        );
    }
;
