import React, {useEffect} from "react";
import {Stock} from "../Domain";
import {numberFormat} from "../../shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort} from "@fortawesome/free-solid-svg-icons";

export const StockPriceTable = (props: { stocks: Stock[] }) => {

    const [sortedStocks, setSortedStocks] = React.useState<Stock[]>([]);


    useEffect(() => {
        setSortedStocks(props.stocks.slice().sort(sortByChange()));
    }, [props.stocks]);

    const sortByName = () => (l: Stock, r: Stock) => l.name.localeCompare(r.name);
    const sortByChange = () => (l: Stock, r: Stock) => (r.price!.change - l.price!.change);
    const setStocksSortedByName = () => setSortedStocks(props.stocks.slice().sort(sortByName()));
    const setStocksSortedByChange = () => setSortedStocks(props.stocks.slice().sort(sortByChange()));

    return <table>
        <thead>
        <tr>
            <td className="ColumnWithSpace" onClick={setStocksSortedByName}>Name <FontAwesomeIcon icon={faSort}/></td>
            <td className="ColumnWithSpace">Price</td>
            <td className="ColumnWithSpace" onClick={setStocksSortedByChange}>Change <FontAwesomeIcon icon={faSort}/>
            </td>
        </tr>
        </thead>
        <tbody>{sortedStocks.map((s: Stock) =>
            <tr className="HoverRow" key={s.id}>
                <td className="ColumnWithSpace">{s.name}</td>
                <td className="ColumnWithSpace">{numberFormat.format(s.price!.current)}</td>
                <td
                    className={(s.price!.change > 0 ? " changePositive" : "changeNegative") + " ColumnWithSpace"}> {s.price?.change.toFixed(2)} %
                </td>
            </tr>)}
        </tbody>
    </table>
}
