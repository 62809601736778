import React from 'react';
import {Investment} from "../Domain";
import {v4 as uuid} from "uuid";
import {numberFormat as nf} from "../../shared";
import "./InvestmentsList.css";
import {SellDialog} from "./SellDialog";
import {Link} from "react-router-dom";

export interface InvestmentsProps {
    investments: Investment[]
}

const positiveChange = (s: Investment) => ((s.numberOfShares * s.price) - s.buyInTotal) > 0;
const changeTotal = (s: Investment) => nf.format((s.numberOfShares * s.price) - s.buyInTotal);
const changeInPercentage = (s: Investment): string => ((((s.numberOfShares * s.price) / s.buyInTotal) * 100) - 100).toFixed(2) + " %";

const InvestmentItem = (props: { investment: Investment }) => {

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const openDialog = () => setDialogOpen(true);

    return <li className="StockListItem">
                <span>
                    {props.investment.name}: {nf.format(props.investment.numberOfShares * props.investment.price)}
                </span>
        <span
            className={props.investment.numberOfShares * props.investment.price > props.investment.buyInTotal ? "large Positive" : "large Negative"}>
            {positiveChange(props.investment) ? "↗" : "↘"} {changeTotal(props.investment)} / {changeInPercentage(props.investment)}
        </span>
        <br/>
        <span>Buy-In Total: {nf.format(props.investment.buyInTotal)} - Buy-In per share: {nf.format(props.investment.buyInPerShare)} - Number: {props.investment.numberOfShares} - Price: {nf.format(props.investment.price)}</span>
        {props.investment.transactions.length > 1 ? props.investment.transactions.map(t => {
            return <span key={t.id}
                         className="block">-&gt; {new Date(t.timestamp).toLocaleDateString("de-DE")}: {t.size} - {t.price} € - {t.transactionType}</span>
        }) : ""}
        <div className="linkContainer">
            <a className="external-link" href={"https://www.tradegate.de/orderbuch.php?isin=" + props.investment.isin}
               target="_blank" rel="noopener noreferrer"><span role="img" aria-label="Tradegate">🔗</span> Tradegate</a>
            <a className="external-link" href={"https://www.ls-tc.de/de/aktie/" + props.investment.isin} target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="Lang&Schwarz">🔗</span> Lang&Schwarz</a>
            <a className="external-link" href={"https://news.guidants.com/#!Ticker/Feed/?txt=" + props.investment.isin}
               target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="New Guidants">🔗</span> News Guidants</a>
            <a className="external-link"
               href={"https://www.wikifolio.com/de/de/s/" + props.investment.isin} target="_blank"
               rel="noopener noreferrer"><span role="img" aria-label="Wifolio">🔗</span>Wikifolio
            </a>
            <Link className="external-link" to={{pathname: `/stock/${props.investment.isin}`}} target="_blank">View
                stock</Link>
        </div>
        <div>
            <button onClick={openDialog}>Sell</button>
            <SellDialog stock={props.investment} open={dialogOpen} setDialogOpen={setDialogOpen}/>
        </div>
    </li>;
};

export const InvestmentsList = (props: InvestmentsProps) => {

    const investments = props.investments?.slice().sort((a, b) =>
        b.numberOfShares * b.price - a.numberOfShares * a.price).map(s => (
        <InvestmentItem key={uuid()} investment={s}/>)
    )

    const totalCurrentValue = props.investments.reduce((sum, currentInvestment) =>
        sum + (currentInvestment.price * currentInvestment.numberOfShares), 0);

    return (
        <div>
            <div className="greenLarge">Total current value: {nf.format(totalCurrentValue)}</div>
            <ul className="InvestmentsList">{investments}</ul>
        </div>
    );

}
