import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {Spinner} from "../shared/components/Spinner";
import {StockPriceTable} from "../shared/components/StockPriceTable";
import {Investment, Stock} from "../shared/Domain";

const STOCKS_WITH_PRICES = gql`
    query StocksWithPrices {
        stocks{
            isin
            id
            name
            price{change closingPriceDayBefore current}
        }
        portfolio{
            investments{
                isin
            }
        }
    }
`;


export function StockChangeOnDailyBase() {

    const [stocks, setStocks] = React.useState<Stock[]>([]);

    const {error, data} = useQuery(STOCKS_WITH_PRICES, {pollInterval: 60000, errorPolicy: "all"});

    useEffect(() => {
        if (data) {
            const investedIsins = data.portfolio.investments.flatMap((i: Investment) => i.isin);
            setStocks(data.stocks.filter((s: Stock) => s.price !== null && s.price?.change !== null && investedIsins.includes(s.isin)));
        }
    }, [data])

    if (error) {
        console.log(error);
    }

    return <div>
        <Spinner isLoading={stocks.length === 0}>
            <div className="Widget"><StockPriceTable stocks={stocks}/></div>
        </Spinner>
    </div>;
}
