import React from "react";
import {Account} from "./Account";
import {gql, useQuery} from "@apollo/client";
import {AccountAzure} from "./AccountAzure";


const QUERY_ACCOUNTS = gql`
    query accounts{
        accounts {
            id
            name
        }
    }
`;

export function Accounts() {

    const [accountName, setAccountName] = React.useState("ING DIBA Stefan");
    const [accountId, setAccountId] = React.useState("");

    const {data, loading, error} = useQuery(QUERY_ACCOUNTS, {pollInterval: 30000});

    return <div>
        <h1>Accounts</h1>
        {error && <p>Error :(</p>}
        {loading && <p>Loading...</p>}
        {data && data.accounts.map((a: {
            id: string;
            name: string
        }) => <button key={a.name}
                      onClick={() => {
                          setAccountName(a.name);
                          setAccountId(a.id);
                      }}>{a.name}
        </button>)}
        <br/>
        {accountName.length > 0 && <AccountAzure id={accountId} name={accountName}/>}
        {accountName.length > 0 && <Account name={accountName}/>}

    </div>;
}
