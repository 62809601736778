export interface Account {
    id: string;
    name: string;
}

export interface Investment {
    id: string;
    isin: string;
    name: string;
    price: number;
    buyInTotal: number;
    buyInPerShare: number;
    numberOfShares: number;
    transactions: Transaction[];
    accounts: Account[];
}

export interface Portfolio {
    cash: number;
    stocksValue: number;
    dividendCollected: number;
    taxesPayed: number;
    amountInvested: number;
    timestamp: Date;
    investments: Array<Investment>;
}

export interface PortfolioHistory {
    portfolioValue: number
    changeInPercentage: number
    amountInvested: number
    date: Date
    cash: number
}

export interface Command {
    name: string
    executedAt: Date
}

export interface StockHistory {
    isin: string
    name: string
    price: number
    date: Date
}

export interface IndexHistory {
    id: string,
    name: string,
    date: string,
    value: number
}

export interface Label {
    id: string;
    key: string;
    value: string;
}

export interface Price {
    current: number
    closingPriceDayBefore: number
    change: number
}

export interface Stock {
    id: string;
    isin: string;
    name: string;
    showChartOnDashboard: boolean;
    collectPrices: boolean;
    wkn: string;
    price?: Price;
    labels: Label[];
}

export type StockInput = Omit<Stock, "labels">;

export interface GraphQLResponse {
    portfolio: Portfolio
    portfolioHistory: [PortfolioHistory]
    portfolioHistoryBetween: [PortfolioHistory]
    latestCommandExecution: Command
    stockHistory: [StockHistory]
    indexHistory: [IndexHistory]
    stocks: [Stock]
}

export enum TransactionType {
    BUY = "BUY", SELL = "SELL", DIVIDEND = "DIVIDEND", TAXES = "TAXES"
}

export interface Transaction {
    id: string;
    timestamp: string;
    isin: string;
    name: string;
    sum: number;
    costs: number;
    transactionType: TransactionType;
    relatedTransactionId?: string;
    size: number,
    price: number,
    accountId?: string,
    account?: {
        id: string,
        name: string
    }
    profitLoss?: {
        sum: number
    }
}

export interface AzureInvestment {
    isin: string,
    currentValueInEuro: number
}

export interface AzurePortfolio {
    currentValueOfStocksInEuro: number,
    currentProfitLossInEuro: number,
    sellSumInEuro: number,
    buySumInEuro: number,
    dividendSumInEuro: number,
    taxesPayedInEuro: number,
    investedSumInEuro: number,
    investments: AzureInvestment[]
}

export interface AzurePortfolioHistory{
    portfolioHistoryEntries: AzurePortfolioHistoryEntry[]
}

export interface AzurePortfolioHistoryEntry{
    date: Date,
    amountInvested: number,
    portfolioValue: number,
    changeInPercentage: number,
    cash: number
}

export interface AzureTransaction {
    id?: string;
    accountId: string;
    isin: string;
    date: Date;
    price: number; // Euro as number
    quantity: number; // BigDecimal as number
    totalCosts: number; // Euro as number
    type: string; // TransactionType as string
}

export interface AzureAccount {
    id: string;
    name: string;
}

export interface AzureStock {
    id: string;
    isin: string;
    name: string;
}
