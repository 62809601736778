import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Stock} from "../shared/Domain";
import {IndexTrendLineChart} from "./IndexTrendLineChart";


const QUERY = gql`
    query indexes {
        stocks{
            id, isin, name, showChartOnDashboard
        }
    }
`;

export const IndexesCharts = () => {

    const {error, loading, data} = useQuery(QUERY);

    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading :(</p>)

    return (
        <React.Fragment>
            {data.stocks
                .filter((os: Stock) => os.isin.length === 0 && os.showChartOnDashboard)
                .sort((left: Stock, right: Stock) => left.name.toLowerCase() > right.name.toLowerCase() ? 1 : -1)
                .map((os: Stock) => <IndexTrendLineChart key={os.id} name={os.name}/>)}
        </React.Fragment>
    )
};
