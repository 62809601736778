import React from 'react';
import './App.css';
import {TradingDashboard} from "./dashboard/TradingDashboard";
import {HashRouter as Router, Link, Route, Switch} from 'react-router-dom';
import {PortfolioHistoryPerYear} from "./PortfolioHistoryPerYear";
import {PortfolioHistoryByDate} from "./PortfolioHistoryByDate";
import {StockConfiguration} from "./StockConfiguration";
import {Commands} from "./Commands";
import {Transactions} from "./Transactions";
import {StockInfo} from "./StockInfo";
import {Accounts} from "./Accounts";
import {Labels} from "./Labels";
import {Config} from "./Config";
import {TransactionsAzure} from "./azure/components/TransactionsAzure";

function App() {
    return (
        <Router>
            <div className="App">
                <nav>
                    <ul id="navigation">
                        <li className="navigation-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/lastYear">YTD</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/history">History</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/stockConfiguration">Stocks</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/accounts">Accounts</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/labels">Labels</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/transactions">Transactions</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/transactionsAzure">Transactions Azure</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/commands">Commands</Link>
                        </li>
                        <li className="navigation-item">
                            <Link to="/config">Config</Link>
                        </li>
                    </ul>
                </nav>

                <Switch>
                    <Route path="/lastYear"> <PortfolioHistoryPerYear/> </Route>
                    <Route path="/history"> <PortfolioHistoryByDate/> </Route>
                    <Route path="/stockConfiguration"> <StockConfiguration/> </Route>
                    <Route path="/transactions"> <Transactions/> </Route>
                    <Route path="/transactionsAzure"> <TransactionsAzure/> </Route>
                    <Route path="/commands"> <Commands/> </Route>
                    <Route path="/accounts"> <Accounts/> </Route>
                    <Route path="/labels"> <Labels/> </Route>
                    <Route path="/stock/:isin" component={StockInfo}/>
                    <Route path="/config"> <Config/> </Route>
                    <Route path="/">
                        <TradingDashboard/>
                    </Route>
                </Switch>
            </div>

        </Router>
    );
}

export default App;
