import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {getLast24Hours} from "../shared";
import {GraphQLResponse, IndexHistory} from "../shared/Domain";
import {HistoryChart} from "./HistoryChart";

const QUERY = gql`
    query indexes ($indexName: String, $from: Date $to: Date) {
        indexHistory( name: $indexName, from: $from, to: $to){
            id
            date
            name
            value
        }
    }
`;

export const IndexTrendLineChart = (props: { name: string }) => {
    const [to, setTo] = React.useState<Date>(new Date());
    const [from, setFrom] = React.useState<Date>(getLast24Hours());
    const [chartData, setChartData] = React.useState(new Array<Array<any>>());
    const [name, setName] = React.useState("");

    const {error, data, refetch} = useQuery(QUERY, {
        variables: {
            indexName: props.name,
            from: from,
            to: to
        },
    });

    useEffect(() => {
        const mapToChartData = (data: GraphQLResponse) => {
            const indexHistoryEntries: [IndexHistory] = data.indexHistory;
            const mappedData: Array<Array<any>> = indexHistoryEntries.map((ih: IndexHistory) => {
                return [new Date(ih.date), ih.value];
            });
            return mappedData;
        };
        if (data) {
            if (data.indexHistory.length === 0) {
                setChartData([["0", "Value"]]);
            } else {
                setName(data.indexHistory[0].name);
                setChartData([["0", "Value"]].concat(mapToChartData(data)));
            }
        }
        const interval = setInterval(() => {
            setTo(new Date())
        }, 60000);
        return () => clearInterval(interval)
    }, [data, props.name])

    if (error) return <div className="Widget">Error while loading data for indexes</div>;

    const changeTimeframe = (hoursBack: number) => {
        const newFromDate = new Date(to);
        newFromDate.setHours(newFromDate.getHours() - hoursBack)
        setFrom(newFromDate);
        refetch().then(() => console.log("refetched successfully"));
    };

    return <HistoryChart chartData={chartData} name={name} changeTimeWindow={changeTimeframe} isin={''}/>
};
