import React, {FormEvent, useEffect} from "react";
import {TransactionsList} from "./shared/components/TransactionsList";
import {gql, useMutation, useQuery} from "@apollo/client";
import Autosuggest, {
    ChangeEvent,
    InputProps, OnSuggestionSelected,
    SuggestionsFetchRequested,
    SuggestionsFetchRequestedParams
} from 'react-autosuggest';
import {Account, Stock, Transaction, TransactionType} from "./shared/Domain";
import {v4 as uuid} from "uuid";
import moment from "moment";
import "./Transactions.css";

const reducer = (state: Transaction, action: { name: string, value: string }) => {
    let value: any = action.value;
    if (action.name === "size" || action.name === "price" || action.name === "costs") value = +value;
    return {
        ...state,
        [action.name]: value
    };
};

const ADD_TRANSACTION = gql`
    mutation addTransctaion($transaction: TransactionInput!) {
        addTransaction(transaction: $transaction)
        {id costs name sum}
    }
`;

const ALL_STOCKS_AND_ACCOUNTS = gql`
    query stocks {
        stocks{id, isin, name}
        accounts{id, name}
    }
`;

let allStocks: Stock[] = [];

const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : allStocks.filter(s => s.name.toLowerCase().indexOf(inputValue.toLowerCase().trim()) >= 0);
};

const getSuggestionValue = (s: Stock) => s.name;

const renderSuggestion = (s: Stock) => (
    <div>{s.name}</div>
);


export function Transactions() {

    const [stockSuggestions, setStockSuggestions] = React.useState<Stock[]>([]);
    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [selectedAccount, setSelectedAccount] = React.useState<Account | undefined>(undefined);

    const [addTransaction] = useMutation(ADD_TRANSACTION);

    const initialState: Transaction = {
        isin: "",
        name: "",
        size: 0,
        sum: 0,
        price: 0,
        costs: 1,
        transactionType: TransactionType.BUY,
        relatedTransactionId: undefined,
        id: uuid(),
        timestamp: new Date().toLocaleDateString("de-DE")
    };
    const [transaction, dispatch] = React.useReducer(reducer, initialState);

    const createTransaction = (event: FormEvent<HTMLFormElement>) => {
        transaction.sum = transaction.price * transaction.size;
        if (transaction.transactionType === TransactionType.TAXES || transaction.transactionType === TransactionType.SELL) {
            transaction.sum *= -1;
        }
        transaction.accountId = selectedAccount!.id;
        transaction.timestamp = moment(transaction.timestamp, "DD.MM.YYYY").toDate().toISOString();
        console.log(transaction);
        addTransaction({variables: {transaction}})
            .then(() => console.log("Transaction added successfully"))
            .catch(reason => {
                console.log(`Error when adding transaction: ${reason}`)
                console.log(reason.networkError.result.errors);
            });
        event.preventDefault();
    };

    const {data, loading, error} = useQuery(ALL_STOCKS_AND_ACCOUNTS);
    useEffect(() => {
        if (data) {
            const stocksOnServer: Stock[] = [...data.stocks];
            stocksOnServer.sort((a: Stock, b: Stock) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            allStocks = stocksOnServer;
            setAccounts(data.accounts);
        }
    }, [data]);
    if (error) return (<p>Error :(</p>)
    if (loading) return (<p>Loading :(</p>)

    const onChangeNameOfStock = (e: React.FormEvent<HTMLElement>, params: ChangeEvent) => dispatch({
        name: "name",
        value: params.newValue
    });
    const inputProps: InputProps<Stock> = {
        required: true,
        value: transaction.name,
        onChange: onChangeNameOfStock
    };

    const onSuggestionsFetchRequested: SuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams): void => setStockSuggestions(getSuggestions(request.value));
    const onSuggestionsClearRequested = () => setStockSuggestions([]);

    const onSuggestionSelected: OnSuggestionSelected<Stock> = (event, data) => dispatch({
        name: "isin",
        value: data.suggestion.isin
    });

    function selectAccount(accountId: string) {
        setSelectedAccount(accounts.find(a => a.id === accountId));
    }

    return (
        <div>
            <form className="TransactionForm" onSubmit={createTransaction}>
                <div className="InputGroup">
                    <label>ISIN:</label>
                    <input type="string" value={transaction.isin}
                           onChange={event => dispatch({name: "isin", value: event.target.value})}/>
                </div>
                <div className="InputGroup">
                    <label>Name of stock:</label>
                    <Autosuggest
                        suggestions={stockSuggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </div>
                <div className="InputGroup">
                    <label>Size:</label>
                    <input type="number" value={transaction.size} required={true}
                           onChange={event => dispatch({name: "size", value: event.target.value})}/>
                </div>
                <div className="InputGroup">
                    <label>Price:</label>
                    <input type="number" step="0.01" required={true} value={transaction.price}
                           onChange={event => dispatch({name: "price", value: event.target.value})}/>
                </div>
                <div className="InputGroup">
                    <label>Costs:</label>
                    <input type="number" step="0.01" required={true} value={transaction.costs}
                           onChange={event => dispatch({name: "costs", value: event.target.value})}/>
                </div>
                <div className="InputGroup">
                    <label>Type:</label>
                    <select defaultValue={transaction.transactionType}
                            onChange={event => dispatch({name: "transactionType", value: event.target.value})}>
                        <option value={TransactionType.SELL}>{TransactionType.SELL}</option>
                        <option value={TransactionType.BUY}>{TransactionType.BUY}</option>
                        <option value={TransactionType.DIVIDEND}>{TransactionType.DIVIDEND}</option>
                        <option value={TransactionType.TAXES}>{TransactionType.TAXES}</option>
                    </select>
                </div>
                <div className="InputGroup">
                    <label>Account:</label>
                    <select defaultValue={transaction.account?.id}
                            onChange={event => selectAccount(event.target.value)}>
                        {accounts.map(a => (
                            <option key={a.id} value={a.id}>{a.name}</option>
                        ))}
                    </select>
                </div>
                <div className="InputGroup">
                    <label>Date:</label>
                    <input type="string" pattern="\d{1,2}\.\d{1,2}\.\d{4}" value={transaction.timestamp}
                           onChange={event => dispatch({name: "timestamp", value: event.target.value})}
                           required={true}/>
                </div>
                <div className="InputGroup"><input type="submit"/></div>
            </form>
            <TransactionsList/>
        </div>
    );
}

