import React, {useEffect} from "react";
import {PortfolioOverview} from "./PortfolioOverview";
import {StocksInPortfolio} from "../shared/components/StocksInPortfolio";
import {TokenInput} from "./TokenInput";
import './TradingDashboard.css';
import {PortfolioHistoryCharts} from "../azure/dashboard/PortfolioHistoryCharts";
import {StockCharts} from "./StockCharts";
import {IndexesCharts} from "./IndexesCharts";
import {StockChangeOnDailyBase} from "./StockChangeOnDailyBase";

export const TradingDashboard = () => {

    const [tokenIsPresent, setTokenIsPresent] = React.useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        setTokenIsPresent(token !== null)
    }, []);

    return (
        <div className="TradingDashboard">
            {tokenIsPresent ?
                <React.Fragment>
                    <PortfolioOverview/>
                    <PortfolioHistoryCharts/>
                    <StockChangeOnDailyBase/>
                    <StocksInPortfolio/>
                    <StockCharts/>
                    <IndexesCharts/>
                </React.Fragment> : null}
            <TokenInput/>
        </div>);
}

