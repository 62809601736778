import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {StockTrendLineChart} from "./dashboard/StockTrendLineChart";
import {Stock} from "./shared/Domain";
import "./LabelComponent.css";
import {StockPriceTable} from "./shared/components/StockPriceTable";


const LABEL_QUERY = gql`
    query label($labelId: String) {
        stocksByLabel(labelId: $labelId){
            id  
            name
            isin
            price {change closingPriceDayBefore current}
        }
    }
`;

export const LabelComponent = (props: { id: string, name: string }) => {

    const [stocks, setStocks] = React.useState<Stock[]>([]);
    const {data, loading, error} = useQuery(LABEL_QUERY, {variables: {labelId: props.id}, errorPolicy: "all"});

    useEffect(() => {
        if (data) {
            setStocks(data.stocksByLabel.slice().filter((s: Stock) => s.price !== null && typeof s.price?.change === "number"));
        }
    }, [data]);

    if (error) {
        console.log(error);
    }
    if (loading) return (<p>Loading...</p>)

    return <div>
        <h1>{props.name}</h1>
        <StockPriceTable stocks={stocks}/>

        {stocks.sort((l: Stock, r: Stock) => l.name.localeCompare(r.name)).map((s: Stock) =>
            <StockTrendLineChart key={s.id} isin={s.isin}/>)
        }
    </div>;
};
