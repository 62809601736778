import React from "react";
import {numberFormat, numberFormat as nf} from "./shared";
import {AzurePortfolio} from "./shared/Domain";
import {AzureBackend} from "./shared/AzureBackend";

export function AccountAzure(props: { id: string, name?: string }) {

    const [portfolio, setPortfolio] = React.useState<AzurePortfolio>({
        buySumInEuro: 0,
        sellSumInEuro: 0,
        currentProfitLossInEuro: 0,
        taxesPayedInEuro: 0,
        dividendSumInEuro: 0,
        investedSumInEuro: 0,
        currentValueOfStocksInEuro: 0
    });


    const [changeAbsolute, setChangeAbsolute] = React.useState<number>(0);
    const [changeInPercentage, setChangeInPercentage] = React.useState<number>(0);

    React.useEffect(() => {
            const azureBackend = new AzureBackend();

            if (props.id === '') return;

            azureBackend.fetchPortfolioByAccountId(props.id).then(data => {
                setPortfolio(data);
                setChangeAbsolute(data.currentValueOfStocksInEuro - data.investedSumInEuro);
                setChangeInPercentage((data.currentValueOfStocksInEuro - data.investedSumInEuro) / data.investedSumInEuro);
            });
        }
        , [props.id]);

    if (props.id === '') return (<p>Loading...</p>)


    return <div>
        <h1>{props.name} (Azure)</h1>
        <div className="Widget">
            <div>sum buy: {nf.format(portfolio.buySumInEuro)}</div>
            <div>sum sell: {nf.format(portfolio.sellSumInEuro)}</div>
            <div>sum dividend: {nf.format(portfolio.dividendSumInEuro)}</div>
            <div>sum taxes: {nf.format(portfolio.taxesPayedInEuro)}</div>

            <div
                className={portfolio.currentProfitLossInEuro > 0 ? "changePositive" : "changeNegative"}>Current
                P/R: {numberFormat.format(portfolio.currentProfitLossInEuro)}
            </div>

            <div className="topBuffer">Invested sum: {numberFormat.format(portfolio.investedSumInEuro)}</div>

            <div className={changeAbsolute > 0 ? "changePositive" : "changeNegative"}>
                Change: {changeAbsolute > 0 ? "↗" : "↘"} {numberFormat.format(changeAbsolute)}
            </div>
            <div className={changeAbsolute > 0 ? "changePositive" : "changeNegative"}>Change in
                %: {(changeInPercentage * 100).toFixed(2)} %
            </div>
        </div>
    </div>;

}